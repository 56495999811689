export default {
	namespaced: true,
	state: {
		isVisible: false,
		debugInfo: {}
	},
	mutations: {
		setVisibility(state, value) {
			state.isVisible = value
		},
		setDebugInfo(state, { key, value }) {
			state.debugInfo[key] = value
		},
		clearDebugInfo(state) {
			state.debugInfo = {}
		}
	},
	actions: {
		toggleDebug({ commit, state }) {
			commit('setVisibility', !state.isVisible)
		},
		addDebugInfo({ commit }, { key, value }) {
			commit('setDebugInfo', { key, value })
		},
		clearDebugInfo({ commit }) {
			commit('clearDebugInfo')
		}
	},
	getters: {
		isDebugVisible: (state) => state.isVisible,
		getDebugInfo: (state) => state.debugInfo
	}
}
