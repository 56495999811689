import { getApp, initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import {
	getFunctions
	// connectFunctionsEmulator
} from 'firebase/functions'
import { getAnalytics, isSupported } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
	apiKey: 'AIzaSyCFE7zTVK5Sjype2zfag-n224m99LoOeog',
	authDomain: 'hubmods-lms.firebaseapp.com',
	projectId: 'hubmods-lms',
	storageBucket: 'hubmods-lms.appspot.com',
	messagingSenderId: '445991523044',
	appId: '1:445991523044:web:48dd7de56096d3dee392f1',
	measurementId: 'G-YWSM9K4HLB'
}

function createFirebaseApp(config) {
	try {
		return getApp()
	} catch {
		return initializeApp(config)
	}
}

const app = createFirebaseApp(firebaseConfig)

// Database Service implementation
class DatabaseService {
	constructor(app) {
		this.app = app
		this.defaultDB = getFirestore(app)
		this.tenantDBs = new Map()
		this.currentTenantId = null
	}

	setTenant(tenantId, isDefault = false) {
		if (window.location.hostname === 'localhost') {
			console.info('setTenant:', tenantId, 'defaultDb:', isDefault)
		}

		this.currentTenantId = tenantId
		if (!isDefault && !this.tenantDBs.has(tenantId)) {
			if (window.location.hostname === 'localhost') {
				console.info('None default db, setting tenant db')
			}
			this.tenantDBs.set(tenantId, getFirestore(this.app, tenantId))
		}
		db = this.getCurrentDB()
	}

	getCurrentDB() {
		if (window.location.hostname === 'localhost') {
			console.info('getCurrentDB:', this.currentTenantId)
		}

		if (!this.currentTenantId) {
			return this.defaultDB
		}
		return this.tenantDBs.get(this.currentTenantId) || this.defaultDB
	}
}

export const dbService = new DatabaseService(app)
export let db = dbService.getCurrentDB()
export const firestore = getFirestore(app)
export const defaultDb = getFirestore(app)
export const auth = getAuth(app)
export const functions = getFunctions(app)
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);
export const storage = getStorage(app)
export const analytics = isSupported().then((yes) => (yes ? getAnalytics(app) : null))
