const state = {
	activeDrawer: null,
	drawerType: null, // 'edit', 'add', etc.
	drawerData: null // Additional data for the drawer
}

const mutations = {
	SET_ACTIVE_DRAWER(state, { euid, type, data }) {
		state.activeDrawer = euid
		state.drawerType = type
		state.drawerData = data
	},
	CLOSE_DRAWER(state) {
		state.activeDrawer = null
		state.drawerType = null
		state.drawerData = null
	}
}

const actions = {
	openDrawer({ commit }, { euid, type, data }) {
		// Always close any existing drawer first
		commit('CLOSE_DRAWER')
		// Then open the new drawer
		commit('SET_ACTIVE_DRAWER', { euid, type, data })
	},
	closeDrawer({ commit }) {
		commit('CLOSE_DRAWER')
	}
}

const getters = {
	isDrawerOpen: (state) => state.activeDrawer !== null,
	activeDrawerEuid: (state) => state.activeDrawer,
	drawerType: (state) => state.drawerType,
	drawerData: (state) => state.drawerData
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
