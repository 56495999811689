<template>
	<div v-if="shouldShowDebug" class="fixed top-4 right-4 z-50">
		<PhDetective
			class="w-6 h-6 text-gray-800 cursor-pointer hover:text-gray-600"
			weight="light"
			@click="toggleDebug" />
	</div>

	<n-drawer
		v-model:show="isVisible"
		:width="drawerWidth"
		placement="right"
		:native-scrollbar="true"
		:mask-closable="false"
		:show-mask="false"
		:block-scroll="false"
		:resizable="true"
		:min-width="300"
		:max-width="800"
		@update:width="handleWidthUpdate">
		<n-drawer-content title="Debug Information" :closable="true">
			<div class="space-y-4">
				<template v-if="Object.keys(debugInfo).length">
					<div v-for="(value, key) in debugInfo" :key="key" class="space-y-2">
						<h3 class="font-semibold text-gray-700">{{ key }}</h3>
						<pre class="bg-gray-100 p-2 rounded text-sm overflow-x-auto">{{
							JSON.stringify(value, null, 2)
						}}</pre>
					</div>
				</template>
				<div v-else class="text-gray-500 italic">
					No debug information available. Add debug info using:
					<pre class="bg-gray-100 p-2 rounded text-sm mt-2">
this.$store.dispatch('debug/addDebugInfo', {
  key: 'yourKey',
  value: yourValue
})</pre
					>
				</div>
			</div>
		</n-drawer-content>
	</n-drawer>
</template>

<script setup>
import { computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
import { NDrawer, NDrawerContent } from 'naive-ui'
import { PhDetective } from '@phosphor-icons/vue'
const user = inject('user')
const store = useStore()
const drawerWidth = ref(400)

const isVisible = computed({
	get: () => store.getters['debug/isDebugVisible'],
	set: () => store.dispatch('debug/toggleDebug')
})

const debugInfo = computed(() => store.getters['debug/getDebugInfo'])

const shouldShowDebug = computed(() => {
	const isLocal = window.location.hostname === 'localhost'
	return isLocal || user?.value?.data?.role?.developer
})

const toggleDebug = () => {
	store.dispatch('debug/toggleDebug')
}

const handleWidthUpdate = (width) => {
	drawerWidth.value = width
}
</script>
