import { db } from '@root/firebaseDatabase'
import { showDanger } from '@utils/AlertService'
import { log } from '@reusables/logger'
import store from '../store'
import {
	collection,
	doc,
	query,
	where,
	orderBy,
	limit,
	startAfter,
	getDocs,
	getDoc,
	setDoc,
	updateDoc,
	serverTimestamp,
	addDoc,
	deleteDoc,
	onSnapshot,
	deleteField,
	increment,
	arrayUnion,
	arrayRemove,
	getCountFromServer,
	writeBatch
} from 'firebase/firestore'

/**
 * Universal function to read/listen to documents or collections
 * @param {Object} params
 * @param {string|FirebaseReference} params.ref - Path to document/collection
 * @param {Object} [params.filter] - Query filters
 * @param {number} [params.filter.limit] - Number of documents to fetch
 * @param {Object} [params.filter.where] - Where conditions
 * @param {Object} [params.filter.orderBy] - Order by conditions
 * @param {Object} [params.filter.startAfter] - Pagination cursor
 * @param {boolean} [params.listen] - Whether to set up real-time listener
 * @param {Function} [params.onUpdate] - Callback for real-time updates
 * @param {boolean} [params.count] - Whether to fetch document count
 * @returns {Promise<Object|Array>|Unsubscribe}
 */
export const readData = async ({ ref, filter, listen, onUpdate, count = false }) => {
	// Handle if ref is already a Firebase reference
	const pathRef =
		typeof ref === 'string'
			? ref.split('/').filter(Boolean).length % 2 === 1
				? collection(db, ref)
				: doc(db, ref)
			: ref

	// Determine if path is collection (odd segments) or document (even segments)
	const segments =
		typeof ref === 'string'
			? ref.split('/').filter(Boolean)
			: pathRef.path.split('/').filter(Boolean)

	const isCollection = segments.length % 2 === 1

	// Build query if collection
	let queryRef = pathRef
	if (isCollection && filter) {
		const constraints = []

		// Where clauses
		if (filter.where) {
			Object.entries(filter.where).forEach(([field, condition]) => {
				constraints.push(where(field, condition.operator, condition.value))
			})
		}

		// OrderBy
		if (filter.orderBy) {
			Object.entries(filter.orderBy).forEach(([field, direction]) => {
				constraints.push(orderBy(field, direction || 'desc'))
			})
		}

		// Pagination - Updated to handle document snapshots correctly
		if (filter.startAfter) {
			// Check if we're getting a raw document snapshot or our formatted version
			const cursorDoc = filter.startAfter._delegate || filter.startAfter
			constraints.push(startAfter(cursorDoc))
		}

		// Limit
		if (filter.limit) {
			constraints.push(limit(filter.limit))
		}

		queryRef = query(pathRef, ...constraints)
	}

	// Handle count request
	if (count) {
		try {
			const snapshot = await getCountFromServer(queryRef)
			return snapshot.data().count
		} catch (error) {
			log('Error fetching document count:', error)
			showDanger(error.message)
			throw error
		}
	}

	// Handle real-time listeners
	if (listen) {
		if (typeof onUpdate !== 'function') {
			throw new Error('onUpdate callback is required for listen')
		}
		return onSnapshot(
			queryRef,
			(snapshot) => {
				if (isCollection) {
					const docs = snapshot.docs.map((doc) => ({
						id: doc.id,
						ref: doc.ref,
						data: doc.data()
					}))
					onUpdate(docs)
				} else {
					onUpdate({
						id: snapshot.id,
						ref: snapshot.ref,
						data: snapshot.data()
					})
				}
			},
			(error) => {
				log('Error listening to data:', error)
				showDanger(error.message)
			}
		)
	}

	// Handle one-time fetch - Updated to return raw snapshot for pagination
	return new Promise((resolve, reject) => {
		const fetchData = isCollection ? getDocs : getDoc

		fetchData(queryRef)
			.then((response) => {
				if (isCollection) {
					const docs = response.docs.map((doc) => ({
						id: doc.id,
						ref: doc.ref,
						data: doc.data(),
						// Store the raw document snapshot for pagination
						_delegate: doc
					}))
					resolve(docs)
				} else {
					resolve({
						id: response.id,
						ref: response.ref,
						data: response.data(),
						_delegate: response
					})
				}
			})
			.catch(reject)
	})
}

/**
 * Universal function to write/update/delete documents
 * @param {Object} params
 * @param {string|FirebaseReference} params.ref - Path to document
 * @param {string} params.operation - 'add'|'set'|'update'|'delete'
 * @param {Object} [params.data] - Data to write (not needed for delete)
 * @param {boolean} [params.merge] - Merge option for set operation
 * @param {boolean} [params.danger] - Show danger alert
 * @returns {Promise<DocumentReference|boolean>}
 */
export const writeData = ({ ref, operation, data, merge = false, danger = true }) => {
	// Handle if ref is already a Firebase reference
	const docRef =
		typeof ref === 'string'
			? dbRef(ref) // Convert string path to reference
			: ref // Use existing reference

	const segments =
		typeof ref === 'string'
			? ref.split('/').filter(Boolean)
			: docRef.path.split('/').filter(Boolean)
	const isCollection = segments.length % 2 === 1
	const currentUser = store.getters.getCurrentUser?.uid

	// Create user reference if currentUser is available
	const userRef = currentUser
		? dbRef(`tenant/${store.getters.getTenantId}/users/${currentUser}`)
		: null

	// Handle adding new document to collection
	if (operation === 'add' && isCollection) {
		return addDoc(docRef, {
			...data,
			createdAt: serverTimestamp(),
			updatedAt: serverTimestamp(),
			createdBy: userRef,
			updatedBy: userRef
		})
	}

	// Handle document operations
	if (isCollection) {
		throw new Error('Invalid document reference')
	}

	const operations = {
		set: () =>
			setDoc(
				docRef,
				{
					...data,
					updatedAt: serverTimestamp(),
					updatedBy: userRef
				},
				{ merge }
			),
		update: () =>
			updateDoc(docRef, {
				...data,
				updatedAt: serverTimestamp(),
				updatedBy: userRef
			}),
		delete: () => deleteDoc(docRef)
	}

	if (!operations[operation]) {
		throw new Error(`Invalid operation: ${operation}`)
	}
	return operations[operation]()
		.then(() => {
			log(`${operation} operation successful on ${docRef.path}`)
			return true
		})
		.catch((error) => {
			if (danger) {
				log(`Error in ${operation} operation:`, error)
				showDanger(error.message)
			}
			throw error
		})
}

/**
 * Creates a Firestore reference based on path segments
 * @param {string} path - Path to document/collection
 * @param {object} [database=db] - Firestore database instance
 * @returns {DocumentReference|CollectionReference}
 */
export const dbRef = (path) => {
	const segments = path.split('/').filter(Boolean)
	const isCollection = segments.length % 2 === 1
	return isCollection ? collection(db, path) : doc(db, path)
}

/**
 * Get path and collection to check if path exists
 * @async
 * @param path
 * @param collection
 * @param tenantId
 * @returns {Promise<boolean>}
 */
export const getDuplicatedPath = async (path, collection, tenantId, type) => {
	const where = {
		path: {
			operator: '==',
			value: path
		}
	}

	let duplicatedPath = false
	if (type === 'update') {
		await readData({
			ref: `/tenant/${tenantId}/${collection}/`,
			filter: { where }
		})
			.then((results) => {
				if (results.length === 1 && results[0].id !== path.id) {
					duplicatedPath = true
					showDanger('The path already exists')
				}
			})
			.catch((error) => {
				log('~ file: DataService.js ~ line 253 ~ getDuplicatedPath ~ error', error)
				showDanger(error.message)
			})

		return duplicatedPath
	}

	await readData({
		ref: `/tenant/${tenantId}/${collection}/`,
		filter: { where }
	})
		.then((results) => {
			if (results.length === 1) {
				duplicatedPath = true
				showDanger('The path already exists')
			}
		})
		.catch((error) => {
			log('~ file: DataService.js ~ line 268 ~ getDuplicatedPath ~ error', error)
			showDanger(error.message)
		})

	await readData({
		ref: `/tenant/${tenantId}/utilities/redirects/`
	})
		.then((response) => {
			const redirects = response.data?.courses
			if (redirects) {
				if (Object.keys(redirects).includes(path)) {
					duplicatedPath = true
					showDanger('The path already exists')
				}
			}
		})
		.catch((error) => {
			log('~ file: DataService.js ~ line 290 ~ await readData ~ error', error)
			showDanger(error.message)
		})

	return duplicatedPath
}

/**
 * Universal function to perform batch updates
 * @param {Array} operations - Array of operations to perform in the batch
 * @returns {Promise<void>}
 */
export const batchUpdate = async (operations) => {
	const batch = writeBatch(db)

	operations.forEach(({ ref, data }) => {
		batch.update(ref, data)
	})

	try {
		await batch.commit()
		log('Batch update successful')
	} catch (error) {
		log('Error in batch update:', error)
		showDanger('Batch update failed')
		throw error
	}
}

// Export the firestore field functions
export { serverTimestamp, deleteField, increment, arrayUnion, arrayRemove }
